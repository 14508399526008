<template>
  <div class="g07">
    <div class="control">
      <el-button @click="save">保存</el-button>
    </div>
    <div class="excel-container" ref="excelContainer"></div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import auth from '@/common/auth'
import utility from '@/common/utility'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    async save () {
      this.$loading()

      // 处理数据
      const sheet = this.workbook.getActiveSheet()

      const jsonData = []
      const rowCount = sheet.getRowCount()
      const columnIndexMap = {
        valid: 16,
        meterageListGuid: 17,
        price: 4,
        firstYearNum: 6,
        firstYearAmount: 7,
        firstYearPercent: 8,
        secondYearNum: 9,
        secondYearAmount: 10,
        secondYearPercent: 11,
        thirdYearNum: 12,
        thirdYearAmount: 13,
        thirdYearPercent: 14
      }

      for (let rowIndex = 2; rowIndex < rowCount; ++rowIndex) {
        if (sheet.getCell(rowIndex, columnIndexMap.valid).value() !== 1) {
          continue
        }
        jsonData.push({
          g08Guid: utility.getUuid(),
          tenderGuid: auth.getUserInfo().tenderGuid,
          planStatExaminationGuid: this.planStatExaminationGuid,
          meterageListGuid: sheet.getCell(rowIndex, columnIndexMap.meterageListGuid).value(),
          firstYearNum: sheet.getCell(rowIndex, columnIndexMap.firstYearNum).value(),
          firstYearAmount: sheet.getCell(rowIndex, columnIndexMap.firstYearAmount).value(),
          firstYearPercent: sheet.getCell(rowIndex, columnIndexMap.firstYearPercent).value(),
          firstYearPrice: sheet.getCell(rowIndex, columnIndexMap.price).value(),
          secondYearNum: sheet.getCell(rowIndex, columnIndexMap.secondYearNum).value(),
          secondYearAmount: sheet.getCell(rowIndex, columnIndexMap.secondYearAmount).value(),
          secondYearPercent: sheet.getCell(rowIndex, columnIndexMap.secondYearPercent).value(),
          secondYearPrice: sheet.getCell(rowIndex, columnIndexMap.price).value(),
          thirdYearNum: sheet.getCell(rowIndex, columnIndexMap.thirdYearNum).value(),
          thirdYearAmount: sheet.getCell(rowIndex, columnIndexMap.thirdYearAmount).value(),
          thirdYearPercent: sheet.getCell(rowIndex, columnIndexMap.thirdYearPercent).value(),
          thirdYearPrice: sheet.getCell(rowIndex, columnIndexMap.price).value(),
          createTime: utility.getCurrentTime(),
          createUserGuid: auth.getUserInfo().userGuid
        })
      }

      try {
        await axios.post(`${config.restHost}/g07/batchAdd`, jsonData, {
          headers: {
            Authorization: `bearer ${auth.getToken()}`
          }
        })

        await this.init()
      } catch (error) {
        console.log(error)
      } finally {
        this.$loading().close()
      }
    },
    async init () {
      this.$loading()

      // 加载excel模版
      const excelTemplate = await axios.get('/excel/G07Template.xlsx', {
        responseType: 'blob'
      })
      this.excelIo = new GC.Spread.Excel.IO()
      this.excelIo.open(excelTemplate.data, json => {
        if (!this.workbook) {
          this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
        }
        this.workbook.fromJSON(json)
      })

      // 加载清单
      this.meterageLists = (await axios.get(`${config.restHost}/g07/meterageList`, {
        params: {
          tenderGuid: auth.getUserInfo().tenderGuid
        },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })).data

      const array = this.meterageLists.map(meterageList => [
        meterageList.meterageDisplayCode,
        meterageList.meterageFullName,
        meterageList.unit,
        meterageList.pictureNum,
        meterageList.price,
        meterageList.pictureAmount
      ])

      const hiddenArray = this.meterageLists.map(meterageList => [meterageList.meterageListGuid])

      // 填充数据
      this.workbook.getActiveSheet().setRowCount(this.meterageLists.length + 2)
      this.workbook.getActiveSheet().setArray(2, 0, array)
      this.workbook.getActiveSheet().setArray(2, 17, hiddenArray)

      // 设置自动行高
      this.workbook.commandManager().execute({
        cmd: 'autoFitRow',
        sheetName: this.workbook.getActiveSheet().name(),
        rows: this.meterageLists.map((_, index) => ({
          row: index + 2
        })),
        columnHeader: false,
        autoFitType: GC.Spread.Sheets.AutoFitType.cell
      })

      this.$loading().close()
    }
  },
  async mounted () {
    await this.init()
  }
}
</script>

<style scoped lang="scss">
.g07 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & .excel-container {
    flex: 1;
  }
}
</style>
